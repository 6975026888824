"use client";
//import { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { theme } from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { IntlMessageProvider, FirebaseProvider, SessionProvider, SessionContext } from './providers';
import { InternalRoutes } from './routes';
import { PageLoader, AlertBox } from './components';
import {IntlProvider} from 'react-intl';
import languages from './intl';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
//import { FreeMerch } from 'modals';

/*
import LogRocket from 'logrocket';

if (process.env.REACT_APP_LOGROCKET)
    LogRocket.init(process.env.REACT_APP_LOGROCKET);
*/

function fallbackRender({ error, resetErrorBoundary }: FallbackProps) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <AlertBox severity="error">
      <p>Something went wrong:</p>
      <p>{error.message}</p>
    </AlertBox>
  );
}

function App() {

  /*
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (!showModal) setShowModal(true);
  }, []);
  */

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme => theme}>
        <CssBaseline/>
        <ErrorBoundary fallbackRender={fallbackRender}>
          <FirebaseProvider>
            <SessionProvider>
              <SessionContext.Consumer>
                {({locale = 'en', ready}) => (
                    <IntlProvider
                        locale={locale || 'en'}
                        defaultLocale="en"
                        messages={languages[locale]}
                      >
                        <IntlMessageProvider>
                          {ready ? (
                            <>
                              <LocalizationProvider dateAdapter={AdapterLuxon}>
                                {/*showModal && <FreeMerch/>*/}
                                <InternalRoutes/>
                              </LocalizationProvider>
                              <ToastContainer autoClose={3000} hideProgressBar/>
                            </>
                          ) : (
                            <PageLoader/>
                          )}
                        </IntlMessageProvider>
                    </IntlProvider>
                )}
              </SessionContext.Consumer>
            </SessionProvider>
          </FirebaseProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default App;

