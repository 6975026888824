import CollectionsIcon from '@mui/icons-material/Collections';
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import SendIcon from '@mui/icons-material/Send';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@mui/icons-material/Home';
import WavingHandOutlinedIcon from '@mui/icons-material/WavingHandOutlined';
import { ROUTES } from 'routes';
import Divider from '@mui/material/Divider';
import QuizIcon from '@mui/icons-material/Quiz';

export const PublicMenu = ({ onClick }:{ onClick: (to: string) => void }) => {

    const nav = (to: string) => {
        onClick(to);
    }

    return (
        <>
            <MenuItem onClick={() => nav(ROUTES.home)}>
                <ListItemIcon>
                    <HomeIcon/>
                </ListItemIcon>
                Home
            </MenuItem>
            <MenuItem onClick={() => nav(ROUTES.episodes)}>
                <ListItemIcon>
                    <HeadphonesOutlinedIcon/>
                </ListItemIcon>
                Episodes
            </MenuItem>
            <MenuItem onClick={() => nav(ROUTES.ringtones)}>
                <ListItemIcon>
                    <PhonelinkRingIcon/>
                </ListItemIcon>
                Ringtones
            </MenuItem>
            <MenuItem onClick={() => nav(ROUTES.gallery)}>
                <ListItemIcon>
                    <CollectionsIcon/>
                </ListItemIcon>
                Gallery
            </MenuItem>
            <MenuItem onClick={() => nav(ROUTES.about)}>
                <ListItemIcon>
                    <WavingHandOutlinedIcon/>
                </ListItemIcon>
                About
            </MenuItem>
            <MenuItem onClick={() => nav(ROUTES.contact)}>
                <ListItemIcon>
                    <SendIcon/>
                </ListItemIcon>
                Contact Us
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => nav(ROUTES.arcade_blank)}>
                <ListItemIcon>
                    <QuizIcon/>
                </ListItemIcon>
                Take the Arcade Game Quiz!
            </MenuItem>
        </>
    )
}