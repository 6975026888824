import { InternalLink, CdnImage } from 'components';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ROUTES } from "routes";
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined';

import type { Episode } from 'types';

interface Props { 
    episode: Episode;
}

export const EpisodeRow = ({ 
    episode: {
        id, 
        title,
        image,
        text,
        published,
        duration,
        season,
    }
 }: Props) => {

    return (
        <Box mb={5}>
            <Box display='flex' flexDirection={{ xs: 'column', md: 'row'}}>
                <Box flex={{ xs: 1, md: '0 175px' }} mr={{ xs: 0, md: 2 }}>
                    <InternalLink 
                        to={ROUTES.episode(id)}
                        style={{ textDecoration: 'underline' }}
                    >
                        <CdnImage src={image}/>
                    </InternalLink>
                </Box>
                <Box flex={1}>
                    <Typography fontWeight='bold' fontSize={'1.1em'}>
                        <InternalLink 
                            to={ROUTES.episode(id)}
                            style={{ textDecoration: 'underline' }}
                        >
                            {!!season ? `Season ${season} ` : ''}{title} <HeadphonesOutlinedIcon/>
                        </InternalLink>
                    </Typography>
                    <Typography display='inline-block' fontSize='.85em'>
                        <Stack direction="row" spacing={2} mt={1} mb={1}>
                            { published && <span><CalendarMonthIcon sx={{verticalAlign:'bottom'}}/> {published.toFormat('LLL dd yyyy')}</span> }
                            { duration && <span><AccessTimeIcon sx={{verticalAlign:'bottom'}}/> {duration} mins</span> }
                        </Stack>
                    </Typography>
                    <Typography paragraph>
                        {text}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}